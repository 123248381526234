import type {ToastServiceMethods} from "primevue";
import type {FormFieldState} from "@primevue/forms/form";


export const processFormErrors = (error: any, toast: ToastServiceMethods, states?: Record<string, FormFieldState>) => {
  if (!error || !error.data) return

  states = states ?? {}

  for (const [key, value] of Object.entries(error.data)) {
    if (states[key]) {
      console.log(states[key], value)
      states[key].errors = Array.isArray(value) ? value : [value]
      states[key].invalid = true
      states[key].valid = false
    } else if (key === 'non_field_errors') {
      if (Array.isArray(value)) {
        value.forEach((message) => {
          toast.add({
            life: 5000,
            severity: 'error',
            summary: 'Error',
            detail: message
          })
        })
      } else {
        toast.add({
          life: 5000,
          severity: 'error',
          summary: 'Error',
          detail: value
        })
      }
    } else if (key === 'detail') {
      toast.add({
        life: 5000,
        severity: 'error',
        summary: 'Error',
        detail: value
      })
    }
  }
}
